<template>
	<div :class="['site-usp', { blackweek: isBlackWeek }]">
		<div class="messages" :class="{ checkout: isCheckout }">
			<site-message v-if="! hideSiteMessage && isBlackWeek"  />
			<div class="banner-info">
				<a-link class="banner-text" :to="formatLink('info', 9, 'Leveransvillkor')">
					<transition-group name="fade" mode="out-in">
						<span v-if="textIndex === 0" key="logged-in-or-not-in-stock">{{ tu('STANDARD_NOT_IN_STOCK') }}</span>
						<span v-if="textIndex === 1" key="logged-in-productpage">
							<template v-if="productPrice < 500">{{ tu('PRODUCTPAGE_UNDER_500') }}</template>
							<template v-else>{{ tu('PRODUCTPAGE_OVER_500') }}</template>
							Leverans {{ earliestDelivery }}
						</span>
						<span v-if="textIndex === 2" key="not-logged-in">{{ tu('NOT_LOGGED_IN') }}</span>
						<span v-if="textIndex === 3" key="logged-in-large-product-in-stock">{{ tu('PRODUCTPAGE_LARGE_PRODUCT') }} Leverans {{ earliestDelivery }}</span>
						<span v-if="textIndex === 4" key="logged-in-large-product-not-in-stock">{{ tu('PRODUCTPAGE_LARGE_PRODUCT') }}</span>
						<span v-if="textIndex === 5" key="not-logged-in-large-product">{{ tu('PRODUCTPAGE_LARGE_PRODUCT_NOT_LOGGED_IN') }}</span>
					</transition-group>
				</a-link>
			</div>
			<site-message v-if="! hideSiteMessage && ! isBlackWeek" />
		</div>
		<div class="navigation">
			<div v-if="mediaQuery.current > mediaQuery.md && ! isCheckout">
				<a-link :to="formatLink('info', 1, 'Kundservice')">
					{{ t('CUSTOMER_SERVICE') }}
				</a-link>
				<a-link :to="formatLink('info', 5, 'Våra butiker')">
					{{ t('STORES') }}
				</a-link>
				<a-link :to="formatLink('campaign', 8827, 'Webhallen Företag')">
					{{ t('B2B') }}
				</a-link>
			</div>
			<portal-target
				name="site-message-navigation"
				slim
			/>
		</div>
	</div>
</template>

<script>
import { t } from 'datastore/i18n';
import mediaQuery from 'mediaQuery';
import { mapGetters, mapState } from 'vuex';
import bus from 'eventbus';
import { standardRoute } from 'utils';

export default {
	data () {
		return {
			mediaQuery,
			productStock: '',
			productPrice: '',
			isShippable: '',
			packageSize: '',
			currentProduct: {},
			shippingMethodIds: [1, 2, 3, 4, 5, 25, 26, 27, 28, 29]
		};
	},
	computed: {
		...mapState({
			deliveryDates: state => state.user.deliveryDates,
			earliestDelivery:  state => state.user.earliestDeliveryDate,
			rankLevel:  state => state.user.rankLevel,
			lowestShippingPrice: state => state.shippingPrices.lowestShippingPrice,
		}),
		isCheckout () {
			return this.$route.name === 'checkout';
		},
		isProductPage () {
			return this.$route.name === 'product';
		},
		...mapGetters({
			cartSum: 'cart/sum',
			viewHistory: 'viewHistory/getHistory',
			userZipCode: 'user/hasZipCode',
		}),
		isBlackWeek () {
			const now = new Date();
			return now >= new Date('2024/11/15 00:00:00') && now <= new Date('2024/12/02 23:59:59');
		},
		textIndex () {
			let textIndex = 0;

			if (this.userZipCode && this.isProductPage) {
				textIndex = this.productStock && this.isShippable ? 1 : 0;
			}

			if (! this.userZipCode) {
				textIndex = 2;
			}

			if (this.lowestShippingPrice > 0 && this.userZipCode && this.isProductPage && this.packageSize > 5 && this.productStock) {
				textIndex = 3;
			}

			if (this.lowestShippingPrice > 0 && this.userZipCode && this.isProductPage && this.packageSize > 5 && ! this.productStock) {
				textIndex = 4;
			}

			if (this.lowestShippingPrice > 0 && ! this.userZipCode && this.isProductPage && this.packageSize > 5) {
				textIndex = 5;
			}

			return textIndex;
		},
		hideSiteMessage () {
			return this.isProductPage && this.currentProduct && this.currentProduct?.manufacturer?.id === 1872; // 1872 = apple
		},
		imiGoShippingMethodsMap () {
			return {
				1: "86@POSTNORD",
				2: "15@POSTNORD",
				3: "52@POSTNORD",
				5: "EMAIL@WEBHALLEN_WEB",
				25: "HOME@BUDBEE",
				26: "HOME@AIRMEE",
				27: "DLVBOX@BUDBEE",
				28: "EXPRESS@INSTABOX",
				29: "INSTAHOME@INSTABOX",
				31: "86@POSTNORD",
				100: "19@POSTNORD",
				101: "17@POSTNORD",
			}
		},
	},
	watch: {
		$route (to, frm) {
			if (frm.name === 'product') {
				this.$store.commit('user/updateEarliestDeliveryDate', this.deliveryDates);
			}
		},
		currentProduct (to, frm) {
			if (to !== frm && this.isProductPage) {
				this.calculateShippingPrices(to);
			}
		},
		isProductPage (to) {
			// Reset all product data if not product page
			if (! to) {
				this.productStock = '';
				this.productPrice = '';
				this.isShippable = '';
				this.packageSize = '';
				this.currentProduct = {};
				this.calculateShippingPrices(null);
			}
		},
	},
	mounted () {
		bus.$on('current.product', this.product);
	},
	beforeDestroy () {
		bus.$off('current.product', this.product);
	},
	methods: {
		t,
		t: t.prefix('NAV'),
		tu: t.prefix('USP'),
		product (product) {
			this.productStock = product.stock.web > 0;
			this.productPrice = product.price.price;
			this.isShippable = product.isShippable;
			this.packageSize = product.packageSizeId;
			this.currentProduct = product;
		},
		formatLink (page, id, name ) {
			return standardRoute(page, { id, name });
		},
		calculateShippingPrices (product) {
			let shippingSiteId = '1';
			if (product.stock.inStockInJupiter === true && product.stock.inStockInCentralStorage === false) {
				shippingSiteId = '997';
			}

			const excludedShippingMethods = this.shippingMethodIds.filter((methodId) => ! product.possibleDeliveryMethods.includes(methodId));
			let excludedMethods = [];
			for (const method in excludedShippingMethods) {
				excludedMethods.push(this.imiGoShippingMethodsMap[excludedShippingMethods[method]]);
			}

			const measurements = product.packageMeasurements.reduce((acc, curr) => {
				return acc + curr
			});

			if (measurements > 600) {
				excludedMethods.push(this.imiGoShippingMethodsMap[31]);
			}

			const now = Math.floor(Date.now() / 1000);
			if (product.release.timestamp < now) {
				const data = {
					postalCode: this.userZipCode ? this.userZipCode : '11349',
					filters: {
						customer_rank_level: this.rankLevel ? this.rankLevel.toString() : '0',
						delivery_method: 'ship',
						excluded_shipping_methods: "['" + excludedMethods.join("','") + "']",
						has_digital_products: product.isDigital,
						is_bulky: product.packageSizeId > 2 ? 'true' : 'false',
						is_corporate_customer: false,
						max_length: (product.largestMeasure / 10).toString(),
						order_value: product.price.price,
						order_volume: Math.ceil((product.packageVolume / 1000000)).toString(),
						order_weight: Math.ceil(product.packageWeight / 1000).toString(),
						shipping_class: product.shippingClass.id,
						shipping_site_id: shippingSiteId
					}
				};
				this.$store.dispatch('shippingPrices/getShippingMethods', { product, rankLevel: this.rankLevel, data });
			}
		},
	},
};

</script>

